//
//	Компонент стилей, которые должны быть загружены и отображены без блика как это происходит со стилями loadCSS
//	но не в critical что бы не раздувать html
//
@import '../../assets/css/common/components-import';



.main {
	opacity: 1;
	visibility: visible; }

.footer {
	opacity: 1;
	visibility: visible; }

.index-wrap {
	padding: 30px 15px 0;

	@include media($lg) {
		padding: 15px 0 0; }

	@include media($md) {
		padding: 0; }

	&__aside {
		position: relative;
		z-index: 100;

		.download-banner {

			@include media($lg) {
				display: none; } } }

	.breadcrumb {
		margin-left: -15px;
		margin-right: -15px;
 }		// padding-top: 0

	.page-header {
		margin-left: -15px;
		margin-right: -15px; } }

.simple-ar-icon {
	display: inline-flex;
	align-items: center;
	color: $c-main;
	&:hover {
		span {
			border-color: transparent;
			padding-right: 5px;
			&::before {
				transform: scale(0); } } }
	span {
		padding-bottom: 5px;
		font-size: 18px;
		line-height: 100%;
		transition: .3s;
		position: relative;
		font-weight: normal;
		&::before {
			content: '';
			width: 100%;
			height: 2px;
			background-color: $c-main;
			position: absolute;
			left: 0;
			bottom: 0;
			transform: scale(1);
			transition: .3s; } }
	.icon {
		fill: $c-main;
		width: 12px;
		height: 12px;
		margin: 0 10px 5px;
		transform: rotate(180deg);
		transition: .3s; } }


.list-style {
	li {
		position: relative;
		padding-left: 17px;
		color: rgba(6, 14, 65, 1);
		font-size: 18px;
		&::before {
			content: '';
			@include sizeblock(7px);
			border-radius: 50%;
			background-color: $c-main;
			position: absolute;
			left: 0;
			top: 0.4em; } } }

.margin-block {
	margin-bottom: var(--marginLg);
	.tblock {
		margin-bottom: 30px; }
	.iotdevice {
		margin-bottom: 40px; }
	.text-block {
		margin-bottom: 30px; } }

.glued-bloks {
	.request {
		margin-bottom: 70px;
		@include media($lg) {
			margin-bottom: 60px; } } }
