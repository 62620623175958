:root {
  --marginSm: 50px;
  --marginMd: 80px;
  --marginLg: 120px;
}

@media screen and (max-width: 1150px) {
  :root {
    --marginLg: 90px;
    --marginMd: 70px;
  }
}

@media screen and (max-width: 576px) {
  :root {
    --marginSm: 80px;
    --marginMd: 60px;
  }
}

.main {
  opacity: 1;
  visibility: visible;
}

.footer {
  opacity: 1;
  visibility: visible;
}

.index-wrap {
  padding: 30px 15px 0;
}

@media screen and (max-width: 1149px) {
  .index-wrap {
    padding: 15px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .index-wrap {
    padding: 0;
  }
}

.index-wrap__aside {
  position: relative;
  z-index: 100;
}

@media screen and (max-width: 1149px) {
  .index-wrap__aside .download-banner {
    display: none;
  }
}

.index-wrap .breadcrumb {
  margin-left: -15px;
  margin-right: -15px;
}

.index-wrap .page-header {
  margin-left: -15px;
  margin-right: -15px;
}

.simple-ar-icon {
  display: inline-flex;
  align-items: center;
  color: #35C8C7;
}

.simple-ar-icon:hover span {
  border-color: transparent;
  padding-right: 5px;
}

.simple-ar-icon:hover span::before {
  transform: scale(0);
}

.simple-ar-icon span {
  padding-bottom: 5px;
  font-size: 18px;
  line-height: 100%;
  transition: .3s;
  position: relative;
  font-weight: normal;
}

.simple-ar-icon span::before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #35C8C7;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: scale(1);
  transition: .3s;
}

.simple-ar-icon .icon {
  fill: #35C8C7;
  width: 12px;
  height: 12px;
  margin: 0 10px 5px;
  transform: rotate(180deg);
  transition: .3s;
}

.list-style li {
  position: relative;
  padding-left: 17px;
  color: #060e41;
  font-size: 18px;
}

.list-style li::before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #35C8C7;
  position: absolute;
  left: 0;
  top: 0.4em;
}

.margin-block {
  margin-bottom: var(--marginLg);
}

.margin-block .tblock {
  margin-bottom: 30px;
}

.margin-block .iotdevice {
  margin-bottom: 40px;
}

.margin-block .text-block {
  margin-bottom: 30px;
}

.glued-bloks .request {
  margin-bottom: 70px;
}

@media screen and (max-width: 1149px) {
  .glued-bloks .request {
    margin-bottom: 60px;
  }
}
